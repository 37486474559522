export const LevelName = ({
    name,
    level = 0
}: {
    name: string;
    level?: number;
}) => {
    const textLines = name?.split("(");
    const line1 = textLines[0];
    const line2 = textLines[1]?.split(")").join("");

    return (
        <div className="flex items-center mx-auto lg:mx-0 text-main font-bold mb-2 inline-block text-xl lg:text-3xl whitespace-nowrap">
            <div className={level === 0 ? "text-gray-300" : ""}>{line1}</div>
            {line2 && (
                <div className="bg-lightGreyBorder rounded-full text-xs font-bold uppercase px-4 py-1 ml-2">
                    {line2}
                </div>
            )}
        </div>
    );
};
