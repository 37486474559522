import { CERTIFICATION_CONFIG } from "lib/constants";
import { Certification, CertificationConfig } from "lib/types";

const LevelLogo = ({ certification }: { certification: Certification }) => {
    const st1 = { fill: "#FFFFFF" };
    if (!certification || certification === 1)
        return (
            <g>
                <path
                    style={st1}
                    d="M48.6,28.5c-0.2,2.7-0.9,4.8-2.3,6.2c-1.3,1.5-3.1,2.2-5.4,2.2c-2.6,0-4.6-1-6-3c-1.4-2-2-5-2-8.9
c0-3.9,0.7-6.9,2.1-8.9c1.4-2.1,3.5-3.1,6.1-3.1c2.1,0,3.7,0.6,4.9,1.9c1.2,1.3,2,3.2,2.4,5.9l-3.6,0.5c-0.3-1.8-0.7-3.1-1.3-3.9
c-0.6-0.8-1.4-1.2-2.4-1.2c-2.9,0-4.3,2.9-4.3,8.8s1.5,8.8,4.4,8.8c2.3,0,3.5-1.7,3.8-5.2H48.6z"
                />
                <path
                    style={st1}
                    d="M51.6,36.4V13.5h11.9v3.2h-8.2V23h7.7v3.2h-7.7v6.9H64l-0.5,3.2H51.6z"
                />
                <path
                    style={st1}
                    d="M77.6,36.4l-3.6-9.2c-0.3,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.5,0h-2v9.1h-3.5V13.5h5.8c2.4,0,4.2,0.6,5.5,1.8
c1.3,1.2,2,2.8,2,4.9c0,1.3-0.3,2.5-0.9,3.5c-0.6,1-1.4,1.8-2.4,2.4l4.2,10.2H77.6z M72.6,24.1c1.5,0,2.6-0.3,3.3-0.9
c0.7-0.6,1-1.6,1-2.9c0-1.3-0.4-2.2-1.1-2.8c-0.7-0.6-1.8-0.9-3.2-0.9h-1.9v7.5H72.6z"
                />
                <path
                    style={st1}
                    d="M87.8,36.4V16.8h-5.4v-3.2h14.4v3.2h-5.4v19.6H87.8z"
                />
                <path style={st1} d="M99.5,36.4V13.5h3.6v22.8H99.5z" />
                <path
                    style={st1}
                    d="M107.7,36.4V13.5h11.6v3.2h-7.9v6.6h7.4v3.2h-7.4v9.7H107.7z"
                />
                <path style={st1} d="M122.3,36.4V13.5h3.6v22.8H122.3z" />
                <path
                    style={st1}
                    d="M130.5,36.4V13.5h11.9v3.2h-8.2V23h7.7v3.2h-7.7v6.9h8.8l-0.5,3.2H130.5z"
                />
                <path
                    style={st1}
                    d="M146,36.4V13.5h6.1c2.9,0,5.1,1,6.7,2.9c1.5,1.9,2.3,4.7,2.3,8.3c0,3.6-0.8,6.5-2.5,8.5
c-1.7,2.1-4,3.1-6.9,3.1H146z M151.8,33.1c1.8,0,3.2-0.7,4.2-2.1c0.9-1.4,1.4-3.5,1.4-6.2c0-2.7-0.4-4.7-1.3-6
c-0.9-1.4-2.2-2-3.9-2h-2.5v16.4H151.8z"
                />
            </g>
        );
    if (certification === 2)
        return (
            <g>
                <path
                    style={st1}
                    d="M40.6,36.4l-1.5-5.7h-6.8l-1.5,5.7h-3.6l6.5-22.8h4.1l6.5,22.8H40.6z M35.7,17.3l-2.6,10.3h5.3L35.7,17.3z"
                />
                <path
                    style={st1}
                    d="M46.8,36.4V13.7H53c2.9,0,5.1,1,6.7,2.9c1.5,1.9,2.3,4.7,2.3,8.3c0,3.6-0.8,6.5-2.5,8.5
   c-1.7,2.1-4,3.1-6.9,3.1H46.8z M52.6,33.2c1.8,0,3.2-0.7,4.2-2.1c0.9-1.4,1.4-3.5,1.4-6.2c0-2.7-0.4-4.7-1.3-6
   c-0.9-1.4-2.2-2-3.9-2h-2.5v16.3H52.6z"
                />
                <path
                    style={st1}
                    d="M69.3,36.4l-6.2-22.8H67l4.4,18.5l4.3-18.5h3.8l-6.1,22.8H69.3z"
                />
                <path
                    style={st1}
                    d="M91.9,36.4l-1.5-5.7h-6.8l-1.5,5.7h-3.6L85,13.7h4.1l6.5,22.8H91.9z M87,17.3l-2.6,10.3h5.3L87,17.3z"
                />
                <path
                    style={st1}
                    d="M108.9,36.4l-7.6-16.5v16.5h-3.3V13.7h3.6l7.6,16.5V13.7h3.3v22.8H108.9z"
                />
                <path
                    style={st1}
                    d="M131.9,28.6c-0.2,2.7-0.9,4.8-2.3,6.2c-1.3,1.5-3.1,2.2-5.4,2.2c-2.6,0-4.6-1-6-3c-1.4-2-2-5-2-8.9
   c0-3.9,0.7-6.8,2.1-8.9c1.4-2.1,3.5-3.1,6.1-3.1c2.1,0,3.7,0.6,4.9,1.9c1.2,1.3,2,3.2,2.4,5.9l-3.6,0.5c-0.3-1.8-0.7-3.1-1.3-3.9
   c-0.6-0.8-1.4-1.2-2.4-1.2c-2.9,0-4.3,2.9-4.3,8.7s1.5,8.7,4.4,8.7c2.3,0,3.5-1.7,3.8-5.2H131.9z"
                />
                <path
                    style={st1}
                    d="M134.9,36.4V13.7h11.8v3.2h-8.2v6.2h7.7v3.2h-7.7v6.9h8.8l-0.5,3.2H134.9z"
                />
                <path
                    style={st1}
                    d="M150.4,36.4V13.7h6.1c2.9,0,5.1,1,6.7,2.9c1.5,1.9,2.3,4.7,2.3,8.3c0,3.6-0.8,6.5-2.5,8.5
   c-1.7,2.1-4,3.1-6.9,3.1H150.4z M156.2,33.2c1.8,0,3.2-0.7,4.2-2.1c0.9-1.4,1.4-3.5,1.4-6.2c0-2.7-0.4-4.7-1.3-6
   c-0.9-1.4-2.2-2-3.9-2h-2.5v16.3H156.2z"
                />
            </g>
        );
    if (certification === 3)
        return (
            <g transform="translate(28,0)">
                <path
                    style={st1}
                    d="M45.9,13.1h5.8c2.4,0,4.3,0.6,5.6,1.8c1.3,1.2,2,3,2,5.3c0,2.4-0.7,4.2-2,5.5s-3.2,2-5.6,2h-2.4v8.9h-3.4
V13.1z M51.5,24.7c2.9,0,4.3-1.5,4.3-4.5c0-2.8-1.4-4.2-4.3-4.2h-2.2v8.7H51.5z"
                />
                <path
                    style={st1}
                    d="M62.3,13.1H68c2.5,0,4.4,0.6,5.7,1.7c1.3,1.2,2,2.8,2,5c0,1.4-0.3,2.6-0.9,3.6c-0.6,1.1-1.4,1.9-2.5,2.5
l4.4,10.6h-3.7l-3.9-9.6C68.9,27,68.4,27,67.8,27h-2.1v9.5h-3.4V13.1z M67.8,24.1c1.6,0,2.7-0.3,3.5-1c0.7-0.7,1.1-1.7,1.1-3.2
c0-2.6-1.5-4-4.6-4h-2.1v8.1H67.8z"
                />
                <path
                    style={st1}
                    d="M93.8,34c-1.5,2.1-3.6,3.2-6.5,3.2c-2.8,0-4.9-1-6.4-3.1c-1.4-2.1-2.2-5.1-2.2-9.2c0-4.1,0.7-7.1,2.2-9.2
c1.5-2.1,3.6-3.1,6.5-3.1c2.8,0,4.9,1,6.4,3.1c1.4,2.1,2.2,5.1,2.2,9.2C96,28.8,95.2,31.9,93.8,34z M83.6,31.8
c0.8,1.5,2.1,2.3,3.8,2.3c1.7,0,3-0.8,3.8-2.3c0.8-1.5,1.2-3.8,1.2-7c0-3.2-0.4-5.6-1.2-7c-0.8-1.5-2.1-2.2-3.8-2.2
c-1.7,0-2.9,0.7-3.8,2.2c-0.8,1.5-1.2,3.8-1.2,7C82.4,28,82.8,30.3,83.6,31.8z"
                />
            </g>
        );
    if (certification === 4)
        return (
            <g transform="translate(28,0)">
                <path
                    style={st1}
                    d="M45.9,13.1h5.8c2.4,0,4.3,0.6,5.6,1.8c1.3,1.2,2,3,2,5.3c0,2.4-0.7,4.2-2,5.5s-3.2,2-5.6,2h-2.4v8.9h-3.4
V13.1z M51.5,24.7c2.9,0,4.3-1.5,4.3-4.5c0-2.8-1.4-4.2-4.3-4.2h-2.2v8.7H51.5z"
                />
                <path
                    style={st1}
                    d="M62.3,13.1H68c2.5,0,4.4,0.6,5.7,1.7c1.3,1.2,2,2.8,2,5c0,1.4-0.3,2.6-0.9,3.6c-0.6,1.1-1.4,1.9-2.5,2.5
l4.4,10.6h-3.7l-3.9-9.6C68.9,27,68.4,27,67.8,27h-2.1v9.5h-3.4V13.1z M67.8,24.1c1.6,0,2.7-0.3,3.5-1c0.7-0.7,1.1-1.7,1.1-3.2
c0-2.6-1.5-4-4.6-4h-2.1v8.1H67.8z"
                />
                <path
                    style={st1}
                    d="M93.8,34c-1.5,2.1-3.6,3.2-6.5,3.2c-2.8,0-4.9-1-6.4-3.1c-1.4-2.1-2.2-5.1-2.2-9.2c0-4.1,0.7-7.1,2.2-9.2
c1.5-2.1,3.6-3.1,6.5-3.1c2.8,0,4.9,1,6.4,3.1c1.4,2.1,2.2,5.1,2.2,9.2C96,28.8,95.2,31.9,93.8,34z M83.6,31.8
c0.8,1.5,2.1,2.3,3.8,2.3c1.7,0,3-0.8,3.8-2.3c0.8-1.5,1.2-3.8,1.2-7c0-3.2-0.4-5.6-1.2-7c-0.8-1.5-2.1-2.2-3.8-2.2
c-1.7,0-2.9,0.7-3.8,2.2c-0.8,1.5-1.2,3.8-1.2,7C82.4,28,82.8,30.3,83.6,31.8z"
                />
            </g>
        );
    if (certification === 5)
        return (
            <g>
                <path
                    style={st1}
                    d="M54,13.1h11.7v3h-8.3v7.3h7.7v3h-7.7v10.2H54V13.1z"
                />
                <path
                    style={st1}
                    d="M82.7,34c-1.5,2.1-3.6,3.2-6.5,3.2c-2.8,0-4.9-1-6.4-3.1c-1.4-2.1-2.2-5.1-2.2-9.2c0-4.1,0.7-7.1,2.2-9.2
                c1.5-2.1,3.6-3.1,6.5-3.1c2.8,0,4.9,1,6.4,3.1c1.4,2.1,2.2,5.1,2.2,9.2C84.9,28.8,84.1,31.9,82.7,34z M72.5,31.8
                c0.8,1.5,2.1,2.3,3.8,2.3c1.7,0,3-0.8,3.8-2.3c0.8-1.5,1.2-3.8,1.2-7c0-3.2-0.4-5.6-1.2-7c-0.8-1.5-2.1-2.2-3.8-2.2
                c-1.7,0-2.9,0.7-3.8,2.2c-0.8,1.5-1.2,3.8-1.2,7C71.3,28,71.7,30.3,72.5,31.8z"
                />
                <path
                    style={st1}
                    d="M88.8,13.1h5.7c2.5,0,4.4,0.6,5.7,1.7c1.3,1.2,2,2.8,2,5c0,1.4-0.3,2.6-0.9,3.6c-0.6,1.1-1.4,1.9-2.5,2.5
                l4.4,10.6h-3.7l-3.9-9.6C95.3,27,94.8,27,94.2,27h-2.1v9.5h-3.4V13.1z M94.2,24.1c1.6,0,2.7-0.3,3.5-1c0.7-0.7,1.1-1.7,1.1-3.2
                c0-2.6-1.5-4-4.6-4h-2.1v8.1H94.2z"
                />
                <path
                    style={st1}
                    d="M111.5,13.1h3.8l6.8,23.5h-3.6l-1.6-6.2h-7.1l-1.6,6.2h-3.4L111.5,13.1z M116.2,27.5l-2.9-11.1l-2.9,11.1
                H116.2z"
                />
                <path
                    style={st1}
                    d="M144.1,36.6h-3.9l-4.3-9.4l-4.4,9.4H128l6.1-12.1l-5.7-11.4h3.9l3.9,8.6l4-8.6h3.5L138,24.3L144.1,36.6z"
                />
            </g>
        );
    return null;
};
export const AdvisorBadge = ({
    id = 0,
    className = ""
}: {
    id?: Certification;
    className?: string;
}) => {
    const config = CERTIFICATION_CONFIG[id];
    const { color, fontColor } = config;
    const st0 = { fill: color };
    const st1 = { fill: "#FFFFFF" };
    const st2 = { fill: fontColor };
    const st3 = { display: "none" };
    const st4 = { display: "inline", fill: "#FFFF" };
    const st5 = { fill: "none" };

    return (
        <svg
            version="1.1"
            x="0px"
            y="0px"
            width="200"
            height="230"
            viewBox="0 0 200 230"
            className={className}
            data-testid="badge"
        >
            <g>
                <path
                    style={st0}
                    d="M193.8,129V0H0.2v129c-0.1,1.4-0.1,2.8-0.1,4.2C0.1,186.6,43.6,230,97,230s96.9-43.4,96.9-96.9
		C193.9,131.8,193.8,130.4,193.8,129z"
                />
                <circle style={st1} cx="97" cy="133" r="82.7" />
                <g>
                    <g>
                        <path
                            style={st2}
                            d="M58.9,130.2c0-12.6,6.9-20.2,17.6-20.2c10.6,0,17.6,7.6,17.6,20.2c0,12.5-6.9,20.2-17.6,20.2
				C65.8,150.4,58.9,142.5,58.9,130.2z M88.6,130.2c0-9.9-4.5-15.8-12.1-15.8c-7.7,0-12.2,6-12.2,15.8c0,9.6,4.5,15.8,12.2,15.8
				C84.2,146.1,88.6,140,88.6,130.2z"
                        />
                        <path
                            style={st2}
                            d="M116.2,132.8c6.4-1.5,8.7-6.2,8.7-10.5c0-5.6-2.7-11.2-12.7-11.2H97.9v38.4h5.1v-34.1h8.5
				c5.9,0,7.9,3,7.9,6.9c0,3.8-1.9,6.9-7.9,6.9h-8.5l2.6,4.3h5.2l9.4,16h5.8L116.2,132.8z"
                        />
                        <path
                            style={st2}
                            d="M32.4,111v38.5h5.2v-16.2h0v-15.1h0v-2.8h18.7V111H32.4z"
                        />
                        <path
                            style={st2}
                            d="M37.7,127.6l2.6,4.3h15.1v-4.3H37.7z"
                        />
                        <path
                            style={st2}
                            d="M147,111h-5.1l-14.6,38.5h5.3l10.8-30.5c0.4-1,0.7-1.9,0.9-2.9h0.1c0.2,1,0.6,2,0.9,2.9l5.4,15h-12.7
				l2.6,4.3h11.6l4,11.1h5.3L147,111z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={st2}
                                d="M67.2,83.4c-1.5-2.5-2.3-3.8-3.8-6.3c-0.8,0.5-1.3,0.8-2.1,1.3c-0.3-0.4-0.4-0.6-0.7-1
					c2.3-1.5,3.4-2.2,5.8-3.4c0.2,0.4,0.3,0.6,0.6,1.1c-0.9,0.5-1.3,0.7-2.2,1.2c1.5,2.6,2.2,3.8,3.6,6.4
					C68,82.9,67.7,83.1,67.2,83.4z"
                            />
                            <path
                                style={st2}
                                d="M75.4,79.3c-0.6-1.4-0.9-2.1-1.4-3.5c-1.2,0.5-1.8,0.7-2.9,1.3c0.6,1.4,1,2.1,1.6,3.5
					c-0.5,0.2-0.8,0.4-1.3,0.6c-1.5-3.1-2.3-4.6-3.8-7.7c0.6-0.3,0.9-0.4,1.5-0.7c0.6,1.3,0.9,1.9,1.5,3.2c1.2-0.6,1.8-0.8,3-1.3
					c-0.5-1.3-0.8-1.9-1.3-3.2c0.6-0.2,0.9-0.4,1.6-0.6c1.2,3.2,1.8,4.8,3,8C76.2,79,76,79.1,75.4,79.3z"
                            />
                            <path
                                style={st2}
                                d="M78.5,78.2c-1.1-3.2-1.6-4.9-2.7-8.1c2.1-0.7,3.1-1,5.2-1.5c0.1,0.5,0.2,0.7,0.3,1.2
					c-1.4,0.4-2.1,0.5-3.5,1c0.3,0.9,0.4,1.3,0.7,2.2c1.3-0.4,1.9-0.6,3.2-0.9c0.1,0.5,0.2,0.7,0.3,1.2c-1.3,0.3-1.9,0.5-3.1,0.9
					c0.3,1,0.5,1.5,0.8,2.5c1.4-0.4,2-0.6,3.4-0.9c0,0.5,0.1,0.7,0.1,1.2C81.2,77.3,80.3,77.6,78.5,78.2z"
                            />
                            <path
                                style={st2}
                                d="M92.6,75.3c-0.2-2.5-0.3-3.7-0.4-6.2c-0.7,2.5-1,3.8-1.5,6.4c-0.4,0-0.6,0.1-1,0.1
					c-1.1-2.4-1.6-3.5-2.9-5.9c0.4,2.5,0.6,3.7,1,6.1C87.3,76,87,76,86.5,76.1c-0.6-3.4-0.9-5-1.5-8.4c0.8-0.1,1.2-0.2,2-0.3
					c1.3,2.5,1.9,3.8,3,6.4c0.5-2.8,0.8-4.1,1.5-6.9c0.8-0.1,1.2-0.1,2-0.1c0.2,3.4,0.2,5.1,0.4,8.5C93.4,75.3,93.1,75.3,92.6,75.3z
					"
                            />
                            <path
                                style={st2}
                                d="M98.6,75.4c-1.1,0-1.9-0.4-2.4-1.2c-0.6-0.7-0.9-1.8-0.9-3.3c0-1.5,0.2-2.6,0.9-3.4
					c0.6-0.8,1.6-1.2,2.9-1.1c1.2,0,2.2,0.5,2.7,1.3c0.6,0.8,0.8,1.9,0.6,3.4c-0.1,1.5-0.5,2.6-1.2,3.3
					C100.5,75.2,99.7,75.5,98.6,75.4z M98.7,74.2c0.6,0,1.1-0.2,1.5-0.7c0.3-0.5,0.6-1.3,0.6-2.5c0.1-1.1,0-2-0.3-2.5
					c-0.3-0.5-0.8-0.8-1.5-0.9c-0.7,0-1.2,0.2-1.6,0.8c-0.3,0.5-0.5,1.4-0.5,2.5c0,1.1,0.1,2,0.4,2.5C97.6,73.9,98,74.2,98.7,74.2z"
                            />
                            <path
                                style={st2}
                                d="M103.4,75.6c0.4-3.4,0.6-5.1,1-8.5c1.1,0.1,1.7,0.2,2.8,0.4c1.3,0.2,2.2,0.8,2.7,1.6
					c0.5,0.8,0.6,1.9,0.3,3.3c-0.3,1.3-0.9,2.3-1.7,2.9c-0.8,0.6-1.8,0.8-2.9,0.6C104.7,75.8,104.3,75.7,103.4,75.6z M105.8,74.7
					c0.7,0.1,1.3,0,1.8-0.5c0.5-0.4,0.8-1.2,1-2.2c0.2-1,0.2-1.8-0.1-2.3c-0.3-0.6-0.8-0.9-1.6-1.1c-0.4-0.1-0.7-0.1-1.1-0.2
					c-0.4,2.4-0.5,3.6-0.9,6.1C105.3,74.6,105.5,74.7,105.8,74.7z"
                            />
                            <path
                                style={st2}
                                d="M110.7,76.9c0.8-3.3,1.3-5,2.1-8.3c2.1,0.5,3.1,0.8,5.2,1.5c-0.2,0.5-0.2,0.7-0.4,1.1
					c-1.4-0.5-2.1-0.7-3.5-1.1c-0.3,0.9-0.4,1.3-0.6,2.2c1.3,0.4,1.9,0.6,3.2,1c-0.2,0.5-0.2,0.7-0.4,1.1c-1.2-0.4-1.9-0.6-3.1-1
					c-0.3,1-0.4,1.5-0.7,2.5c1.4,0.4,2.1,0.6,3.4,1.1c-0.2,0.4-0.3,0.6-0.6,1.1C113.4,77.7,112.5,77.4,110.7,76.9z"
                            />
                            <path
                                style={st2}
                                d="M120.4,80.4c0-1.5,0.1-2.2,0-3.7c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.1-0.5-0.2-0.8-0.3
					c-0.5,1.3-0.8,1.9-1.3,3.2c-0.5-0.2-0.8-0.3-1.3-0.5c1.2-3.2,1.8-4.8,3-8c1,0.4,1.5,0.6,2.5,1c1,0.4,1.7,1,2.1,1.6
					c0.4,0.7,0.4,1.3,0,2c-0.2,0.4-0.5,0.8-0.9,1c-0.4,0.2-0.8,0.4-1.3,0.4c0,1.7,0,2.5,0,4.2C121.3,80.8,121,80.6,120.4,80.4z
					 M120.4,75.4c0.6,0.2,1.1,0.3,1.4,0.2c0.4-0.1,0.7-0.4,0.9-0.8c0.2-0.4,0.2-0.8,0-1.1c-0.2-0.3-0.6-0.6-1.2-0.9
					c-0.3-0.1-0.5-0.2-0.8-0.3c-0.4,1-0.6,1.6-1,2.6C119.9,75.2,120.1,75.2,120.4,75.4z"
                            />
                            <path
                                style={st2}
                                d="M126.7,83.8c0.3-2.8,0.3-4.2,0.3-7c-1.2,2.2-1.8,3.3-3,5.4c-0.5-0.3-0.7-0.4-1.2-0.6c1.6-3,2.4-4.6,3.9-7.6
					c0.6,0.3,0.9,0.5,1.5,0.8c0,2.9,0,4.3-0.2,7.1c1.3-2.1,2-3.2,3.3-5.3c0.5,0.3,0.8,0.5,1.3,0.8c-1.9,2.9-2.8,4.3-4.7,7.1
					C127.4,84.2,127.2,84.1,126.7,83.8z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={st2}
                                d="M59.7,174.9c-0.8-0.5-1.1-0.8-1.9-1.4c0.3-0.4,0.4-0.5,0.7-0.9c1.9,1.5,2.9,2.2,4.9,3.4
					c-0.2,0.4-0.4,0.6-0.6,0.9c-0.8-0.5-1.2-0.7-1.9-1.3c-1.7,2.5-2.5,3.7-4.2,6.1c-0.5-0.4-0.8-0.6-1.3-0.9
					C57.1,178.5,58,177.3,59.7,174.9z"
                            />
                            <path
                                style={st2}
                                d="M64.4,176.6c0.8,0.5,1.2,0.7,2,1.2c0.9,0.5,1.5,1,1.7,1.7c0.3,0.6,0.3,1.3-0.1,2c-0.2,0.4-0.5,0.8-0.9,1
					c-0.4,0.2-0.8,0.3-1.4,0.3c0,1.7,0,2.6,0,4.3c-0.6-0.3-0.9-0.4-1.5-0.7c0-1.6,0-2.3,0.1-3.9c-0.2-0.1-0.4-0.1-0.6-0.3
					c-0.3-0.2-0.5-0.3-0.8-0.5c-0.7,1.2-1,1.8-1.7,3c-0.5-0.3-0.8-0.5-1.3-0.8C61.7,181,62.6,179.6,64.4,176.6z M64.3,181.3
					c0.6,0.3,1.1,0.5,1.5,0.4c0.4-0.1,0.7-0.3,1-0.8c0.4-0.8,0.1-1.6-1-2.2c-0.3-0.2-0.4-0.3-0.7-0.4c-0.6,1-0.9,1.5-1.5,2.6
					C63.8,181,64,181.1,64.3,181.3z"
                            />
                            <path
                                style={st2}
                                d="M72.5,180.8c0.6,0.2,0.8,0.4,1.4,0.6c-0.3,3.6-0.3,5.4-0.3,9c-0.6-0.2-0.9-0.3-1.5-0.5
					c0-0.9,0.1-1.4,0.1-2.3c-1.2-0.4-1.7-0.7-2.9-1.2c-0.6,0.7-0.9,1.1-1.6,1.8c-0.6-0.3-0.8-0.4-1.4-0.7
					C68.9,184.8,70.1,183.5,72.5,180.8z M72.3,186.4c0.1-1.7,0.2-2.5,0.4-4.2c-1,1.3-1.6,2-2.7,3.2C70.9,185.8,71.4,186,72.3,186.4z
					"
                            />
                            <path
                                style={st2}
                                d="M76.1,182.2c0.6,0.2,0.8,0.3,1.4,0.5c-0.2,2.9-0.2,4.4-0.2,7.3c1.6-2.5,2.3-3.7,3.8-6.3
					c0.5,0.1,0.8,0.2,1.4,0.3c-1.8,3.1-2.7,4.6-4.8,7.6c-0.7-0.2-1-0.3-1.7-0.5C75.8,187.5,75.9,185.7,76.1,182.2z"
                            />
                            <path
                                style={st2}
                                d="M83.4,184.3c1.8,0.4,2.8,0.6,4.6,0.8c-0.1,0.4-0.1,0.7-0.2,1.1c-1.3-0.2-2-0.3-3.4-0.6
					c-0.2,1-0.3,1.5-0.5,2.4c1.3,0.3,2,0.4,3.3,0.6c-0.1,0.4-0.1,0.7-0.2,1.1c-1.3-0.2-2-0.3-3.3-0.6c-0.2,1.1-0.3,1.6-0.5,2.7
					c1.6,0.3,2.4,0.4,3.9,0.7c-0.1,0.4-0.2,0.6-0.4,1.1c-2.1-0.3-3.2-0.5-5.3-1C82.2,189.3,82.6,187.6,83.4,184.3z"
                            />
                            <path
                                style={st2}
                                d="M89.5,185.3c0.5,0.1,0.8,0.1,1.3,0.1c-0.3,3-0.4,4.4-0.7,7.4c1.5,0.1,2.3,0.2,3.8,0.3
					c-0.1,0.4-0.2,0.7-0.3,1.1c-2.1-0.1-3.1-0.2-5.2-0.4C88.9,190.4,89.1,188.7,89.5,185.3z"
                            />
                            <path
                                style={st2}
                                d="M99.3,185.7c0.6,0,0.9,0,1.5-0.1c1.3,3.4,2,5,3.5,8.3c-0.6,0.1-1,0.1-1.6,0.1c-0.4-0.9-0.5-1.3-0.9-2.2
					c-1.2,0.1-1.8,0.1-3.1,0.1c-0.3,0.9-0.4,1.4-0.7,2.3c-0.6,0-0.9,0-1.5,0C97.8,190.9,98.3,189.2,99.3,185.7z M101.4,190.9
					c-0.6-1.6-0.9-2.4-1.4-4c-0.4,1.6-0.6,2.4-1,4.1C100,191,100.5,190.9,101.4,190.9z"
                            />
                            <path
                                style={st2}
                                d="M111.7,192.1c-0.4,0.4-0.9,0.7-1.4,1c-0.5,0.2-1,0.4-1.6,0.5c-1.3,0.2-2.3,0-3-0.7
					c-0.7-0.7-1.2-1.7-1.3-3.2c-0.1-0.9-0.1-1.7,0.1-2.4c0.2-0.7,0.4-1.3,0.9-1.7c0.4-0.4,1-0.7,1.8-0.9c1.6-0.3,2.6,0.4,3.3,1.9
					c-0.5,0.2-0.8,0.3-1.3,0.4c-0.2-0.5-0.5-0.9-0.8-1.1c-0.3-0.2-0.7-0.3-1.1-0.2c-0.5,0.1-0.9,0.3-1.1,0.6
					c-0.2,0.3-0.4,0.8-0.4,1.3c0,0.5,0,1.1,0.1,1.7c0.2,1.2,0.4,2,0.9,2.5c0.4,0.5,1.1,0.7,1.8,0.5c0.6-0.1,1.2-0.3,1.5-0.7
					c-0.2-0.8-0.2-1.2-0.4-2.1c-0.7,0.1-1,0.2-1.7,0.3c-0.1-0.4-0.1-0.6-0.2-1.1c1.2-0.2,1.8-0.3,3-0.6
					C111.2,189.9,111.3,190.6,111.7,192.1z"
                            />
                            <path
                                style={st2}
                                d="M111.6,184.1c1.8-0.4,2.7-0.7,4.6-1.3c0.1,0.4,0.2,0.6,0.3,1c-1.3,0.4-2,0.6-3.3,1c0.3,1,0.4,1.4,0.6,2.4
					c1.3-0.3,1.9-0.5,3.2-0.9c0.1,0.4,0.2,0.6,0.3,1.1c-1.3,0.4-1.9,0.6-3.3,0.9c0.3,1.1,0.4,1.6,0.7,2.7c1.6-0.4,2.3-0.6,3.9-1.1
					c0.1,0.4,0.1,0.7,0.1,1.1c-2.1,0.7-3.1,1-5.2,1.5C112.8,189.1,112.4,187.4,111.6,184.1z"
                            />
                            <path
                                style={st2}
                                d="M117.6,182.3c0.5-0.2,0.8-0.3,1.3-0.5c2.1,2,3.2,2.9,5.4,4.7c-1-2.4-1.5-3.5-2.5-5.9
					c0.5-0.2,0.7-0.3,1.2-0.5c1.4,3.1,2.1,4.7,3.5,7.8c-0.6,0.3-0.9,0.4-1.4,0.6c-2.3-1.8-3.4-2.7-5.6-4.7c0.9,2.4,1.3,3.6,2.2,6
					c-0.5,0.2-0.8,0.3-1.3,0.5C119.2,187.2,118.7,185.6,117.6,182.3z"
                            />
                            <path
                                style={st2}
                                d="M127.6,182.7c1,2,2.2,2.7,3.4,2c1-0.5,1.2-1.4,0.6-2.6c0.5-0.3,0.8-0.5,1.3-0.8c0.4,0.9,0.5,1.7,0.3,2.5
					c-0.2,0.8-0.8,1.4-1.8,1.9c-1.1,0.6-2.1,0.7-3,0.3c-0.9-0.4-1.6-1.3-2.3-2.6c-0.6-1.3-0.8-2.5-0.7-3.4c0.2-1,0.7-1.7,1.7-2.2
					c0.7-0.4,1.4-0.5,2.1-0.4c0.7,0.2,1.3,0.6,2,1.3c-0.5,0.4-0.7,0.6-1.2,0.9c-0.5-0.5-0.9-0.9-1.3-1c-0.4-0.1-0.8-0.1-1.2,0.1
					C126.6,179.4,126.6,180.7,127.6,182.7z"
                            />
                            <path
                                style={st2}
                                d="M136.4,178.2c0.8,1.2,1.2,1.7,2.1,2.9c-0.5,0.4-0.8,0.6-1.3,0.9c-0.8-1.2-1.2-1.8-2-3c-2-1-3-1.6-4.9-2.7
					c0.5-0.3,0.8-0.5,1.3-0.8c1.4,0.9,2.1,1.3,3.6,2.2c-0.3-1.7-0.4-2.5-0.8-4.2c0.5-0.3,0.7-0.5,1.2-0.9
					C135.9,174.8,136.1,175.9,136.4,178.2z"
                            />
                        </g>
                    </g>
                </g>
                <g
                    id="Pro_00000121276270068181650010000009506185007381677974_"
                    style={st3}
                >
                    <path
                        style={st4}
                        d="M72.7,36.4V13.6h5.7c2.4,0,4.2,0.6,5.5,1.8c1.3,1.2,1.9,2.9,1.9,5.2c0,2.3-0.6,4.1-1.9,5.4
			c-1.3,1.3-3.1,1.9-5.4,1.9h-2.2v8.5H72.7z M78.4,24.9c2.6,0,4-1.4,4-4.2c0-1.4-0.3-2.4-1-3c-0.6-0.6-1.7-1-3-1h-2.1v8.1H78.4z"
                    />
                    <path
                        style={st4}
                        d="M99.4,36.4l-3.6-9.2c-0.3,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.5,0h-2v9.1h-3.5V13.6h5.8c2.4,0,4.2,0.6,5.5,1.8
			c1.3,1.2,2,2.8,2,4.9c0,1.3-0.3,2.5-0.9,3.5s-1.4,1.8-2.4,2.4l4.2,10.2H99.4z M94.4,24.2c1.5,0,2.6-0.3,3.3-0.9
			c0.7-0.6,1-1.6,1-2.9c0-1.3-0.4-2.2-1.1-2.8c-0.7-0.6-1.8-0.9-3.2-0.9h-1.9v7.5H94.4z"
                    />
                    <path
                        style={st4}
                        d="M113.6,37c-2.7,0-4.8-1-6.2-3c-1.4-2-2.1-5-2.1-8.9s0.7-6.9,2.2-9c1.4-2,3.5-3.1,6.3-3.1c2.7,0,4.8,1,6.2,3
			c1.4,2,2.1,5,2.1,8.9s-0.7,6.9-2.2,9C118.5,36,116.4,37,113.6,37z M113.7,33.8c1.6,0,2.8-0.7,3.5-2.1c0.8-1.4,1.1-3.6,1.1-6.6
			c0-3-0.4-5.2-1.1-6.6c-0.7-1.4-1.9-2.1-3.5-2.1c-1.6,0-2.8,0.7-3.5,2.1c-0.8,1.4-1.1,3.6-1.1,6.6c0,3,0.4,5.2,1.1,6.6
			C110.9,33.1,112.1,33.8,113.7,33.8z"
                    />
                </g>
                <rect
                    x="34.3"
                    y="13.1"
                    style={st5}
                    width="127.3"
                    height="24.8"
                />
                <LevelLogo certification={id} />

                <g style={st3}>
                    <path
                        style={st4}
                        d="M36.9,13.1h5.8c2.4,0,4.3,0.6,5.6,1.8c1.3,1.2,2,3,2,5.3c0,2.4-0.7,4.2-2,5.5s-3.2,2-5.6,2h-2.4v8.9h-3.4
			V13.1z M42.6,24.7c2.9,0,4.3-1.5,4.3-4.5c0-2.8-1.4-4.2-4.3-4.2h-2.2v8.7H42.6z"
                    />
                    <path
                        style={st4}
                        d="M53.4,13.1h5.7c2.5,0,4.4,0.6,5.7,1.7c1.3,1.2,2,2.8,2,5c0,1.4-0.3,2.6-0.9,3.6c-0.6,1.1-1.4,1.9-2.5,2.5
			l4.4,10.6h-3.7l-3.9-9.6C60,27,59.5,27,58.9,27h-2.1v9.5h-3.4V13.1z M58.9,24.1c1.6,0,2.7-0.3,3.5-1c0.7-0.7,1.1-1.7,1.1-3.2
			c0-2.6-1.5-4-4.6-4h-2.1v8.1H58.9z"
                    />
                    <path
                        style={st4}
                        d="M84.9,34c-1.5,2.1-3.6,3.2-6.5,3.2c-2.8,0-4.9-1-6.4-3.1c-1.4-2.1-2.2-5.1-2.2-9.2c0-4.1,0.7-7.1,2.2-9.2
			c1.5-2.1,3.6-3.1,6.5-3.1c2.8,0,4.9,1,6.4,3.1c1.4,2.1,2.2,5.1,2.2,9.2C87.1,28.8,86.3,31.9,84.9,34z M74.7,31.8
			c0.8,1.5,2.1,2.3,3.8,2.3c1.7,0,3-0.8,3.8-2.3c0.8-1.5,1.2-3.8,1.2-7c0-3.2-0.4-5.6-1.2-7c-0.8-1.5-2.1-2.2-3.8-2.2
			c-1.7,0-2.9,0.7-3.8,2.2c-0.8,1.5-1.2,3.8-1.2,7C73.5,28,73.9,30.3,74.7,31.8z"
                    />
                    <path
                        style={st4}
                        d="M106.1,36.6h-3.4V17.5l-4.8,3L96.5,18l6.6-4.2h3V36.6z"
                    />
                    <path
                        style={st4}
                        d="M111.7,25.2c0-4.1,0.6-7.1,1.8-9c1.2-2,3-2.9,5.4-2.9c2.4,0,4.1,1,5.3,2.9c1.2,2,1.7,5,1.7,9
			c0,4.1-0.6,7.1-1.8,9c-1.2,2-3,2.9-5.4,2.9c-2.4,0-4.1-1-5.3-2.9C112.3,32.2,111.7,29.2,111.7,25.2z M122.1,30.4
			c0.2-1.3,0.4-3.1,0.4-5.2c0-2.2-0.1-4-0.3-5.3c-0.2-1.3-0.6-2.3-1.1-2.8c-0.5-0.6-1.2-0.9-2.1-0.9c-0.9,0-1.6,0.3-2.1,0.9
			c-0.5,0.6-0.9,1.5-1.2,2.8c-0.2,1.3-0.4,3.1-0.4,5.3c0,2.2,0.1,4,0.3,5.3c0.2,1.3,0.6,2.3,1.2,2.8c0.5,0.6,1.2,0.9,2.1,0.9
			c0.9,0,1.6-0.3,2.2-0.9C121.5,32.7,121.8,31.7,122.1,30.4z"
                    />
                    <path
                        style={st4}
                        d="M128.2,25.2c0-4.1,0.6-7.1,1.8-9c1.2-2,3-2.9,5.4-2.9c2.4,0,4.1,1,5.3,2.9c1.2,2,1.7,5,1.7,9
			c0,4.1-0.6,7.1-1.8,9c-1.2,2-3,2.9-5.4,2.9c-2.4,0-4.1-1-5.3-2.9C128.7,32.2,128.2,29.2,128.2,25.2z M138.6,30.4
			c0.2-1.3,0.4-3.1,0.4-5.2c0-2.2-0.1-4-0.3-5.3c-0.2-1.3-0.6-2.3-1.1-2.8c-0.5-0.6-1.2-0.9-2.1-0.9c-0.9,0-1.6,0.3-2.1,0.9
			c-0.5,0.6-0.9,1.5-1.2,2.8c-0.2,1.3-0.4,3.1-0.4,5.3c0,2.2,0.1,4,0.3,5.3c0.2,1.3,0.6,2.3,1.2,2.8c0.5,0.6,1.2,0.9,2.1,0.9
			c0.9,0,1.6-0.3,2.2-0.9C137.9,32.7,138.3,31.7,138.6,30.4z"
                    />
                    <path
                        style={st4}
                        d="M145.9,13.1h3.4v10.1l7.5-10.1h3.7l-6.8,9.1l7.5,14.5h-3.8l-6-11.8l-2.1,2.6v9.2h-3.4V13.1z"
                    />
                </g>
            </g>
        </svg>
    );
};
