import { UserProfile } from "api/user/profile";
import { AdvisorBadge } from "components/AdvisorBadge";
import { CERTIFICATION_CONFIG } from "lib/constants/certification.constants";
import { Subtitle } from "./Subtitle";
import { LevelName } from "./LevelName";
import { LearnMoreLink } from "./LearnMoreLink";
import { ReactElement, ReactNode } from "react";

export const AdvisorLevelDetails = ({
    userProfile,
    showLearnMore = false
}: {
    userProfile: UserProfile;
    showLearnMore?: boolean;
}) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentLevel = userProfile.certification_type_id || 0;
    const levelConfig = CERTIFICATION_CONFIG[currentLevel];
    let upLevelTitle = `Maintain your ${levelConfig.shortName} status`;
    let upLevelSubtitle = "Grow your business";
    let steps = levelConfig.tasksToKeepLevel;
    let nextLevelLimit = levelConfig.limit;
    if (levelConfig.nextLevel) {
        const nextLevelConfig = CERTIFICATION_CONFIG[levelConfig.nextLevel];
        upLevelTitle = `Qualify for ${
            levelConfig.nextLevelPrefix ? `${levelConfig.nextLevelPrefix} ` : ""
        }${nextLevelConfig.shortName}`;
        upLevelSubtitle = "Next up";
        steps = nextLevelConfig.tasksToAchieveLevel;
        nextLevelLimit = nextLevelConfig.limit;
    }
    return (
        <div className="bg-white rounded border border-stroke shadow-card p-8 lg:p-8 mb-6">
            <div className="flex flex-col lg:flex-row lg:gap-6">
                <div className="flex gap-4 lg:gap-6">
                    <div>
                        <AdvisorBadge
                            id={currentLevel}
                            className="w-[70px] lg:w-[92px] h-auto"
                        />
                    </div>
                    <div>
                        <Subtitle className="text-main mr-1  lg:mr-0 normal-case uppercase text-secondary text-xs mt-0">
                            {currentYear} Status
                        </Subtitle>
                        <LevelName
                            name={levelConfig.name}
                            level={currentLevel}
                        />
                        {nextLevelLimit && (
                            <p className="text-label text-xs lg:mb-2">
                                {nextLevelLimit}
                            </p>
                        )}
                    </div>
                </div>
                <hr className="bg-slate-300 w-full my-3 lg:hidden mx-auto my-4" />
                <div className="lg:border-l lg:pl-6">
                    <Subtitle>{upLevelSubtitle}</Subtitle>
                    <p className="text-md font-medium  mb-2">{upLevelTitle}</p>
                    {steps && steps.length > 0 && (
                        <ul className="list-disc ml-5 text-secondary text-left  lg:px-0 text-[12px]">
                            {steps?.map(
                                (
                                    step: string | ReactElement,
                                    index: number
                                ) => (
                                    <li key={index}>{step}</li>
                                )
                            )}
                        </ul>
                    )}
                    {showLearnMore && <LearnMoreLink className="block mt-3" />}
                </div>
            </div>
        </div>
    );
};
