import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const Subtitle = ({
    children,
    className = ""
}: {
    children: ReactNode;
    className?: string;
}) => {
    return (
        <h4
            className={twMerge(
                `text-secondary uppercase text-xs font-bold mb-1 ${className}`
            )}
        >
            {children}
        </h4>
    );
};
